export default {
  formcall: "Form Call",
  recommendationcall: "Recommendation Call",
  prequalificationcall: "Pre Qualification Call",
  adhoc: "AdHoc Call",
  productworkshop: "Product Workshop",
  entrepreneurreferences: "Enterpreneur References",
  customerreferences: "Customer References",
  peopleworkshop: "People Workshop",
  technologyworkshop: "Tech Workshop",
  commercialworkshop: "Commercial Workshop",
  useofproceedsworkshop: "Use of Proceeds Workshop",
  investorreferences: "Investor References",
  aggregatedscorecard: "Aggregated Scorecard",
};
