import auth from "./Auth";
import Config from "../config";
import request from "superagent";

export default class AggregatedScorecardStore {
  constructor() {
    this.baseUrl = `${Config.api.host}/organizations`;
  }

  aggregateScorecard(organizationId) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .post(`${this.baseUrl}/${organizationId}/scorecard/aggregated`)
          .set("Accept", "application/json")
          .set("Authorization", `Bearer ${authData.token}`)
          .set("InReach-Client", authData.client)
      )
      .catch((error) => {
        if (
          error.message.includes("timeout") ||
          error.message.includes("terminated")
        ) {
          return Promise.resolve({});
        }
        return Promise.reject(error);
      });
  }

  deleteAggregatedScorecards(organizationId) {
    return auth
      .getAuthData()
      .then((authData) =>
        request
          .delete(`${this.baseUrl}/${organizationId}/scorecard/aggregated`)
          .set("Accept", "application/json")
          .set("Authorization", `Bearer ${authData.token}`)
          .set("InReach-Client", authData.client)
      )
      .catch((error) => {
        if (
          error.message.includes("timeout") ||
          error.message.includes("terminated")
        ) {
          return Promise.resolve({});
        }
        return Promise.reject(error);
      });
  }
}
